<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="isShowycjl">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      <el-breadcrumb-item>进出记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/Parking' }"
        >停车记录</el-breadcrumb-item
      >
      <el-breadcrumb-item>进出记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
        v-if="isShowycjl"
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
            @change="pkDoor"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >手机号码：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入手机号码"
            v-model="query.mobile"
            clearable
          />
        </label>
        <label style="margin: 10px 50px" for=""
          >车牌：<el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车牌号"
            v-model.trim="query.plateNo"
            clearable
        /></label>
        <label style="margin: 10px" for=""
          >进出标志：
          <el-select
            v-model="query.inoutFlag"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="进" :value="0"></el-option>
            <el-option label="出" :value="1"></el-option>
          </el-select>
        </label>

        <label style="margin: 10px" for=""
          >进出车道：
          <el-select
            v-model="query.doorId"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in pkDoorList"
              :key="item.id"
              :label="item.doorName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >进出时间：<el-date-picker
            v-model="inTimeBegin"
            @change="inTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="inTimeEnd"
            @change="outTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></label>
        <div v-show="showHandover == true">
          <label style="margin: 10px" for=""
            >车主姓名：
            <el-input
              style="width: 200px"
              size="small"
              placeholder="请输入车主姓名"
              v-model="query.trueName"
              clearable
            />
          </label>
          <label style="margin: 10px" for=""
            >是否开闸：
            <el-select
              v-model="query.isOpenDoor"
              clearable
              placeholder="请选择"
              style="width: 200px"
              size="small"
            >
              <el-option label="未开闸" :value="0"></el-option>
              <el-option label="开闸" :value="1"></el-option>
            </el-select>
          </label>
          <label style="margin: 5px" for="">
            年份:
            <el-date-picker
              size="small"
              v-model="query.t_year"
              type="year"
              placeholder="选择年份"
              format="yyyy"
              value-format="yyyy"
              style="width: 185px"
              :picker-options="pickerOptions"
              @change="tYearchange"
            />
          </label>
        </div>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="pkInoutLogcx"
          >查询</el-button
        >
        <el-button size="small" @click="pkInoutLogcz">重置</el-button>
        <div class="deployment">
          <div v-show="showHandover == false" @click="showHandover = true">
            展开<i class="el-icon-arrow-down"></i>
          </div>
          <div v-show="showHandover == true" @click="showHandover = false">
            收起<i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button type="primary" size="small" @click="pkInoutLogexcel"
        >导出</el-button
      >
      <el-button
        type="danger"
        size="small "
        icon="el-icon-delete"
        @click="batchdel"
        v-if="isShowycjl"
        >删除选中记录</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          @sort-change="sortchange"
          @selection-change="handleSelectionChange"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            type="selection"
            width="55"
            v-if="isShowycjl"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="车牌号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="trueName"
            label="用户"
          >
            <template slot-scope="scope">
              <span v-if="!scope.row.trueName">临时车</span>
              <span v-else>{{ scope.row.trueName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="mobile"
            label="手机号"
          >
            <template slot-scope="scope">
              <span v-if="!scope.row.mobile">-</span>
              <span v-else>{{ scope.row.mobile }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="userKindId"
            label="用户类型"
            :formatter="judgecustomerType"
          >
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inoutFlag"
            label="进出标志"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.inoutFlag === 1">出</span>
              <span v-if="scope.row.inoutFlag === 0">进</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inOutTime"
            label="进出时间"
            sortable="custom"
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.inOutTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inOutPicPath"
            label="进出照片"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.inOutPicPath"
                :preview-src-list="imgList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="doorName"
            label="进出通道"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="isOpenDoor"
            label="是否开闸"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenDoor == 0">未开闸</span>
              <span v-if="scope.row.isOpenDoor == 1">开闸</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="noOpenDoorReason"
            label="异常原因"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenDoor == 0">{{
                findQuery(
                  scope.row.noOpenDoorReason,
                  scope.row.inoutFlag,
                  gateList
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="130"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="delClick(scope.row.id)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              >
              <el-button type="text" @click="journalClick(scope)" size="mini"
                >详细日志</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 详细日志 -->
    <el-dialog
      :title="`${plateNoxxrz}的详细进出日志`"
      :visible.sync="isShowPayment"
      width="50%"
      v-el-drag-dialog
    >
      <el-table
        :data="queryPaymentList"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="createTime" label="支付时间">
          <template slot-scope="scope">
            <span>{{
              scope.row.createTime ? conversionTime(scope.row.createTime) : ''
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="remarks" label="内容"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  pkInoutLog,
  delpkInoutLog,
  findByInoutdataId,
  pkDoor,
  pkInoutLogexcel,
  findPkCarLog,
} from '@/api/record.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
import { sysDictData, userKind } from '@/api/dictionaries.js'

export default {
  data() {
    return {
      showHandover: true,
      isShowPayment: false,
      isShowycjl: true,
      isShowData: false,
      tableData: [],
      inTimeBegin: undefined, // 开始时间
      inTimeEnd: undefined, // 结束时间
      query: {
        // 查询条件
        current: 1,
        size: 10,
        t_year: undefined,
        pk_id: undefined,
        doorId: undefined, // 通道id
        trueName: undefined,
        plateNo: undefined, // 车牌号
        mobile: undefined,
        inoutFlag: undefined, // 进出标记 0进入 1外出
        inTimeBegin: undefined, // 开始时间
        inTimeEnd: undefined, // 结束时间
        isOpenDoor: undefined, // 是否开闸 0-否 1-是
        sortField: 'in_out_time', // 进出场时间in_out_time
        sortType: 'desc', // 排序类型 默认desc  asc升序 desc降序
      },
      querys: {
        // 查询条件
        current: 1,
        size: 10,
        inoutDataId: undefined, // id
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      batchid: '', // 保存批量选中的id
      pkDoorList: [], // 保存通道列表
      plateNoxxrz: undefined,
      queryPaymentList: [],
      gateList: [],
      imgList: [],
      // 用户类型
      customerType: [],
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  computed: {
    // 控制年份日期范围
    pickerOptions() {
      const currentDate = new Date()
      const currentYear = new Date().getFullYear()
      // 获取当前月份和日期
      let currentMonth = currentDate.getMonth() + 1
      let currentDay = currentDate.getDate()
      // 检查是否大于等于6月1日
      let isAfterJune =
        currentMonth > 6 || (currentMonth === 6 && currentDay >= 1)
      return {
        disabledDate(time) {
          return isAfterJune
            ? time.getFullYear() < 2020 || time.getFullYear() > currentYear
            : time.getFullYear() < 2020 ||
                time.getFullYear() == currentYear - 1 ||
                time.getFullYear() > currentYear
        },
      }
    },
  },
  methods: {
    tYearchange(value) {
      if (value == null) {
        this.query.t_year = undefined
      } else {
        this.query.t_year = value
      }
    },
    findQuery(code, code1, list) {
      // 翻译字典
      if (code) {
        const itemName = list.filter((item) => item.code == code)
        return itemName.length ? itemName[0].name : ''
      } else {
        if (code1 == 1) {
          return '未支付'
        } else {
          return ''
        }
      }
    },
    sysDictData() {
      // 字典查询
      sysDictData({ typeId: 88 }).then((res) => {
        this.gateList = res.data.data
      })
    },
    inTimechange() {
      this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 30 - 1000
    },
    outTimechange() {
      if (this.inTimeBegin === null || this.inTimeBegin === undefined) {
        this.inTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.inTimeEnd < this.inTimeBegin) {
        this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.inTimeEnd - this.inTimeBegin > 3600 * 1000 * 24 * 7) {
        this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 30 - 1000
        return this.$message.warning('不可选择比开始时间超过一个月以外的日期')
      }
    },
    pkInoutLogexcel() {
      if (this.inTimeBegin - this.inTimeEnd > 3600 * 1000 * 24 * 7) {
        return this.$message.warning('只能导出一个礼拜以内的数据')
      }
      this.query.inTimeEnd = this.inTimeEnd
      this.query.inTimeBegin = this.inTimeBegin
      if (this.query.inTimeEnd || this.query.inTimeBegin) {
        this.query.inTimeEnd = (this.query.inTimeEnd / 1000).toFixed(0)
        this.query.inTimeBegin = (this.query.inTimeBegin / 1000).toFixed(0)
      }
      if (!this.query.inTimeEnd || !this.query.inTimeBegin) {
        return this.$message.warning('请选择导出的具体时间')
      }
      pkInoutLogexcel(this.query, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          window.open(res.data.data)
        }
      })
    },
    sortchange(column, prop, order) {
      if (column.order == 'descending') {
        this.query.sortType = 'desc'
      } else if (column.order == 'ascending') {
        this.query.sortType = 'asc'
      }
      this.pkInoutLog()
    },
    pkDoor() {
      // 获取通道下拉
      pkDoor({ pk_id: this.query.pk_id }).then((res) => {
        this.pkDoorList = res.data.data
      })
    },
    pkInoutLog() {
      const currentYear = new Date().getFullYear()
      if (this.query.t_year == currentYear) {
        this.query.t_year = undefined
      }
      // 进出记录查询列表
      this.query.inTimeBegin = this.inTimeBegin
      this.query.inTimeEnd = this.inTimeEnd
      if (this.query.inTimeBegin && !this.query.inTimeEnd) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.inTimeBegin && this.query.inTimeEnd) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.inTimeBegin || this.query.inTimeEnd) {
        this.query.inTimeBegin = this.query.inTimeBegin / 1000
        this.query.inTimeEnd = this.query.inTimeEnd / 1000
      }
      pkInoutLog(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.total = res.data.data.total
        this.tableData = res.data.data.list
        this.imgList = res.data.data.list.map((item) => item.inOutPicPath)
        this.userKind()
        if (this.tableData) {
          this.isShowData = true
        }
      })
    },
    pkInoutLogcx() {
      // 查询
      this.query.current = 1
      this.pkInoutLog()
    },
    pkInoutLogcz() {
      // 重置
      this.query.pk_id = this.PkInfoSelect[0].id
      this.query.trueName = undefined
      this.query.doorId = undefined
      this.query.plateNo = undefined
      this.query.inoutFlag = undefined
      this.query.mobile = undefined
      this.query.isOpenDoor = undefined
      this.inTimeBegin = undefined
      this.inTimeEnd = undefined
      this.pkInoutLog()
    },
    delClick(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delpkInoutLog(id, { pk_id: this.query.pk_id }).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              this.pkInoutLog()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    journalClick(scope) {
      // 详细日志
      this.plateNoxxrz = scope.row.plateNo
      const obj = {
        dataId: scope.row.inoutDataId,
        inoutLogId: scope.row.id,
      }
      findPkCarLog(obj, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          this.queryPaymentList = res.data.data
          this.isShowPayment = true
        }
      })
    },
    findByInoutdataId() {
      // 根据订车记录ID查询订单记录
      this.isShowycjl = false
      this.querys.inoutDataId = this.$route.query.id
      findByInoutdataId(this.querys, { pk_id: this.query.pk_id }).then(
        (res) => {
          if (res.data.code === '200') {
            this.total = res.data.data.total
            this.tableData = res.data.data.list

            if (this.tableData) {
              this.isShowData = true
            }
          }
        }
      )
    },
    handleSelectionChange(val) {
      // 点击table选中
      this.batchid = ''
      val.forEach((item) => {
        this.batchid += item.id + '_'
      })
      this.batchid = this.batchid.slice(0, this.batchid.length - 1)
    },
    batchdel() {
      // 批量删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delpkInoutLog(this.batchid, { pk_id: this.query.pk_id }).then(
            (res) => {
              if (res.data.code === '200') {
                this.$message.success('删除成功')
                this.pkInoutLog()
              }
            }
          )
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          if (this.$route.query.pkid) {
            this.query.pk_id = this.$route.query.pkid
          } else {
            this.query.pk_id = this.PkInfoSelect[0].id
          }
          if (this.$route.query.id) {
            this.findByInoutdataId()
          } else {
            this.pkInoutLog()
          }
          this.pkDoor()
          this.sysDictData()
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      if (this.$route.query.id) {
        this.querys.size = val
        this.findByInoutdataId()
      } else {
        this.query.size = val
        this.pkInoutLog()
      }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      if (this.$route.query.id) {
        this.querys.current = val
        this.findByInoutdataId()
      } else {
        this.query.current = val
        this.pkInoutLog()
      }
    },
    // 查询用户类型
    userKind() {
      userKind({ pk_id: this.query.pk_id }).then((res) => {
        this.customerType = res.data.data
      })
    },
    // 判断用户类型
    judgecustomerType(row, column) {
      for (let index = 0; index < this.customerType.length; index++) {
        if (row[column.property] == this.customerType[index].id) {
          return this.customerType[index].kindName
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.deployment {
  color: #1677ff;
  cursor: pointer;
  margin-left: 10px;
}
::v-deep .el-image-viewer__close {
  color: #333;
}
::v-deep .el-image-viewer__mask {
  position: fixed;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
::v-deep .el-image-viewer__canvas {
  height: 80%;
  padding-top: 100px;
  box-sizing: border-box;
}
::v-deep .el-image-viewer__wrapper {
  top: 50px;
  left: 20%;
  right: 20%;
  bottom: 50px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 30px 1px #333;
}
</style>
